interface Props {
  status: "pending" | "cancelled" | "completed";
}

const Status = ({ status }: Props) => {
  return (
    <div
      className={[
        "rounded uppercase border py-2 px-6 text-sm",
        status === "pending"
          ? "border-warning-main border-solid text-warning-main font-semibold bg-warning-200 "
          : "",
        status === "completed"
          ? "border-success-main border-solid text-success-main font-semibold bg-secondary-200"
          : "",
        status === "cancelled"
          ? "border-error-700 border-solid text-error-700 font-semibold bg-error-200"
          : "",
      ].join(" ")}
    >
      {status === "cancelled" ? "FAILED" : status}
    </div>
  );
};

export default Status;
