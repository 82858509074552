import { Transaction } from "src/models/Transaction";
import QRContent from "./QRContent";
import { Img } from "src/components/elements";
import useMountOpenModal from "src/hooks/useMountOpenModal";
import ModalHowToPay from "../../common/ModalHowToPay";

interface Props {
  transactionData: Transaction;
}

const CodeContent = ({ transactionData }: Props) => {
  const [modal, createModal] = useMountOpenModal();
  return (
    <div className="px-2 flex justify-center flex-col py-24 items-center flex-1 max-w-[33.75rem] w-full mx-auto">
      <QRContent transactionData={transactionData} />
      <div className="flex flex-col-reverse xl:flex-row gap-8 items-center justify-between p-4 xl:px-24 pb-8 xl:fixed inset-x-0 xl:bottom-8">
        <div className="flex items-center">
          <div className="text-[#ACACAC] font-bold uppercase mr-3 text-sm">
            Powered by
          </div>
          <Img src="/images/qrph.png" alt="QR Ph Logo" />
        </div>

        <button
          onClick={() =>
            createModal(ModalHowToPay, {
              channelName: transactionData.channel.name,
            })
          }
          className="flex flex-col items-center"
        >
          <Img src="/images/faq.png" alt="FAQ" />
          <div className="text-[#0057e4] font-bold text-sm">How to pay</div>
        </button>
      </div>
      {modal}
    </div>
  );
};

export default CodeContent;
