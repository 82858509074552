import { Img } from "src/components/elements";
import { Transaction } from "src/models/Transaction";
import addComma from "src/utils/addComma";
import Status from "../common/Status";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import {
  BASE_64_GCASH_LOGO,
  BASE_64_QRPH_LOGO,
  downloadPersonalize,
  handleStatus,
} from "./utils";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  transactionData: Transaction;
}

const ContentTheme2 = ({ transactionData }: Props) => {
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const personalizeQrCodeRef = useRef<HTMLCanvasElement>(null);

  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const { failure_redirect_url, status, success_redirect_url } =
      transactionData;

    const handleCountDown = setTimeout(() => {
      if (
        ((failure_redirect_url && status === "cancelled") ||
          (success_redirect_url && status === "completed")) &&
        countdown > 0
      ) {
        setCountdown(countdown - 1);
      }
    }, 1000);

    if (countdown <= 0) {
      handleStatus({
        status,
        success_redirect_url,
        failure_redirect_url,
      });
      clearTimeout(handleCountDown);
    }

    return () => {
      clearTimeout(handleCountDown);
    };
  }, [countdown, transactionData]);

  return (
    <div className="px-4 flex items-start justify-center flex-col min-h-screen bg-[#F3F6F8] py-12 relative">
      <div className="absolute top-[2.5rem] left-1/2 -translate-x-1/2 max-w-[293px] w-full  z-50 ">
        <AnimatePresence>
          {transactionData?.failure_redirect_url &&
            transactionData.status === "cancelled" && (
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                className=" text-[#3D3D3D] bg-white w-full text-xs text-center p-4 font-semibold rounded-xl shadow-[0px_4px_30px_0px_rgba(0,_0,_0,_0.10)]"
              >
                {transactionData?.failure_redirect_url &&
                  transactionData.status === "cancelled" &&
                  `The transaction was unsuccessful. Please try again. Meanwhile, you will be redirected back to the merchant page in ${countdown} seconds.`}
              </motion.div>
            )}
        </AnimatePresence>

        <AnimatePresence>
          {transactionData?.success_redirect_url &&
            transactionData.status === "completed" && (
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                className=" text-[#3D3D3D] bg-white w-full text-xs text-center p-4 font-semibold rounded-xl shadow-[0px_4px_30px_0px_rgba(0,_0,_0,_0.10)]"
              >
                {transactionData?.success_redirect_url &&
                  transactionData.status === "completed" && (
                    <p>
                      Your payment is successful. Redirection <br /> to merchant
                      page in {countdown} sec...
                    </p>
                  )}
              </motion.div>
            )}
        </AnimatePresence>
      </div>

      <div className=" max-w-[909px] w-full mx-auto flex flex-col">
        <div className=" uppercase flex items-center self-start bg-white p-4 rounded-t-xl">
          <div className="text-xs font-bold text-[#ACACAC] font-open-sans mr-1">
            Powered by
          </div>
          <Img src="/images/qrph.png" height="20" width="90" />
        </div>

        <div className="bg-white p-4 md:py-12 rounded-b-xl rounded-tr-xl">
          <div className="max-w-[725px] w-full mx-auto">
            <div className="flex flex-col md:flex-row text-center md:text-left gap-4 md:gap-8 items-center  justify-between">
              <div>
                <div className="text-2xl md:text-3xl font-bold">
                  PHP {addComma((transactionData.amount || 0).toFixed(2))}
                </div>
                <div className="text-sm font-bold text-[#ACACAC]">
                  Amount to pay
                </div>
              </div>

              <Status status={transactionData.status} />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 pt-8">
              <div className="md:border-r md:border-[#E6EAED] flex flex-col items-center space-y-4">
                <div className="p-4 bg-[#0440A2] rounded-2xl flex flex-col items-center">
                  <div className="bg-white rounded-xl p-4" ref={qrCodeRef}>
                    <QRCodeSVG
                      className=" max-w-[80vw] object-contain"
                      value={transactionData.qrcode}
                      height={240}
                      width={240}
                      level="H"
                      imageSettings={{
                        src:
                          transactionData.channel.slug === "gcash-qrph"
                            ? BASE_64_GCASH_LOGO
                            : BASE_64_QRPH_LOGO,
                        width: 30,
                        height: 30,
                        excavate: true,
                      }}
                    />
                  </div>
                  <div className=" font-bold text-2xl text-white pt-2">
                    Scan to pay
                  </div>
                </div>

                <div className="uppercase font-bold text-base">or</div>

                <button
                  type="button"
                  onClick={() =>
                    downloadPersonalize({
                      transactionData,
                      personalizeQrCodeRef,
                      qrCodeRef,
                    })
                  }
                  className="hover:translate-y-[-2px] hover:shadow-md transition-all ease-linear uppercase bg-[#0440A2] rounded-3xl w-full max-w-[272px] text-white text-base font-bold p-3"
                >
                  Download QR
                </button>
              </div>

              <div className="flex flex-col justify-center  md:items-end w-full p-2 sm:p-4 md:p-0">
                <div className="flex items-center  md:mx-auto md:max-w-[322px] w-full">
                  <div className="border-4 rounded-full border-[#0440A280] h-10 w-10 flex flex-col items-center justify-center mr-4">
                    <svg
                      width="13"
                      height="19"
                      viewBox="0 0 13 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.25053 12.5306V11.6148C4.25053 10.8228 4.41966 10.1339 4.75793 9.5481C5.0962 8.96232 5.71499 8.33941 6.61429 7.67937C7.48059 7.06058 8.04987 6.5573 8.32214 6.16953C8.60265 5.78176 8.74291 5.34861 8.74291 4.87008C8.74291 4.3338 8.5449 3.9254 8.14888 3.64489C7.75286 3.36437 7.20007 3.22411 6.49053 3.22411C5.25296 3.22411 3.84213 3.62839 2.25804 4.43693L0.909088 1.72665C2.74894 0.695342 4.70018 0.179688 6.7628 0.179688C8.4624 0.179688 9.81135 0.588086 10.8097 1.40488C11.8162 2.22168 12.3195 3.31074 12.3195 4.67207C12.3195 5.57962 12.1132 6.36342 11.7007 7.02346C11.2882 7.68349 10.5044 8.42604 9.34932 9.25108C8.55728 9.83687 8.054 10.2824 7.83949 10.5877C7.63322 10.8929 7.53009 11.2931 7.53009 11.7881V12.5306H4.25053ZM3.85451 16.7631C3.85451 16.0701 4.04014 15.5462 4.41141 15.1914C4.78269 14.8367 5.32309 14.6593 6.03263 14.6593C6.71742 14.6593 7.24545 14.8408 7.61672 15.2038C7.99624 15.5668 8.18601 16.0866 8.18601 16.7631C8.18601 17.4149 7.99624 17.9306 7.61672 18.3101C7.2372 18.6814 6.70917 18.867 6.03263 18.867C5.33959 18.867 4.80331 18.6855 4.42379 18.3225C4.04427 17.9512 3.85451 17.4314 3.85451 16.7631Z"
                        fill="#0440A2"
                      />
                    </svg>
                  </div>
                  <div className="text-2xl font-bold">How to pay</div>
                </div>

                <ol className=" list-decimal text-sm pt-4 pl-4 text-[#3D3D3D] md:mx-auto md:max-w-[322px] w-full">
                  <li>
                    Begin by launching the {transactionData.channel.name} mobile
                    app.
                  </li>
                  <li>
                    Access the QR Code Scanner feature and scan or download the
                    QR Code and upload it. A payment summary screen will be
                    shown once it has been scanned or uploaded.
                  </li>
                  <li>
                    Adhere to any required extra steps needed to complete the
                    payment process.
                  </li>
                  <li>
                    An SMS confirmation will be sent to your mobile number once
                    the payment has been made.
                  </li>
                  <li>
                    You will be automatically redirected back to the merchant
                    once the payment process is complete.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <canvas ref={personalizeQrCodeRef} className="hidden"></canvas>
    </div>
  );
};

export default ContentTheme2;
