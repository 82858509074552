import _ from "lodash";
import useSWR from "swr";
import axios from "axios";

const useCustomSWR = (url: string | null, options: any = {}) => {
  const fetcher = (apiUrl: string) => axios.get(apiUrl).then((res) => res.data);

  const { data, error, mutate } = useSWR(url ? url : null, fetcher, {
    dedupingInterval: 3000,
    ...options,
  });

  return {
    ..._.omit(data, ["data"]),
    data: data?.data,
    error,
    mutate,
  };
};

export default useCustomSWR;
