import { RefObject } from "react";
import { Transaction } from "src/models/Transaction";

export const banks = [
  {
    title: "Unionbank",
    slug: "unionbank",
    logo: "/images/qrph.png",
  },
  //     Maya
  // Unionbank
  // PNB
  // Gcash
  // Grabpay
  // Shopeepay
  // Robinsons Bank
];

export const downloadPersonalize = ({
  transactionData,
  personalizeQrCodeRef,
  qrCodeRef,
}: {
  transactionData: Transaction;
  personalizeQrCodeRef: RefObject<HTMLCanvasElement>;
  qrCodeRef: RefObject<HTMLDivElement>;
}) => {
  if (personalizeQrCodeRef.current) {
    const canvas = personalizeQrCodeRef.current;
    canvas.width = 400;
    canvas.height = 480;
    const c = canvas.getContext("2d");
    const x = canvas.width / 2;

    if (c) {
      c.fillStyle = "#fff";
      c.fillRect(0, 0, canvas.width, canvas.height);
      const qrCodeElement = qrCodeRef.current?.querySelector("svg");
      if (qrCodeElement) {
        const svgString = new XMLSerializer().serializeToString(qrCodeElement);

        const dataUri = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
          svgString
        )}`;

        if (!c) {
          return;
        }

        const img = new Image();
        img.src = dataUri;
        const poweredByQrphImg = new Image();
        poweredByQrphImg.src = "/images/powered-by-qrph-2.png";

        img.onload = () => {
          c.drawImage(img, 64, 70, 272, 272);
          poweredByQrphImg.onload = () => {
            c.drawImage(poweredByQrphImg, 133.5, 370, 133, 70);
            const pngDataUri = canvas.toDataURL("image/png");
            const link = document.createElement("a");
            link.href = pngDataUri;
            link.download = `qrph-qr-code-${transactionData.reference_no}.png`;
            // Programmatically click the link to start the download
            link.click();
          };
          c.font = "bold 20px arial";
          c.textAlign = "center";
          c.fillStyle = "#3d3d3d";
          c.fillText("Scan or upload to pay", x, 40);
        };
      }
    }
  }
};
