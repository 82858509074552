import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import CodeContent from "src/components/modules/channel-theme-1/code";
import Failed from "src/components/modules/channel-theme-1/failed";
import GcashContent from "src/components/modules/channel-theme-1/gcash";
import Loading from "src/components/modules/channel-theme-1/loading";
import ContentTheme2 from "src/components/modules/channel-theme-2";
import useCustomSWR from "src/hooks/useCustomSWR";
import useMetaTitle from "src/hooks/useMetaTitle";
import { Transaction } from "src/models/Transaction";

const ReferenceNoPage = () => {
  const params = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const theme = queryParams.get("t") || "";

  const [hostName, setHostName] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);

  const { data, error }: { data: Transaction | null; error: any } =
    useCustomSWR(
      params.reference_no && hostName && isError === false
        ? hostName === process.env.REACT_APP_HOSTNAME
          ? `${process.env.REACT_APP_API_URL_PROD}/v1/qrph?reference_no=${params.reference_no}`
          : `${process.env.REACT_APP_API_URL_DEV}/v1/qrph?reference_no=${params.reference_no}`
        : null,
      { refreshInterval: 3000 }
    );

  useEffect(() => {
    const newHostName = window.location.hostname;
    if (newHostName) {
      setHostName(newHostName);
    }
  }, []);

  useEffect(() => {
    if (error) {
      setIsError(true);
    }
  }, [error]);

  useMetaTitle(
    data?.channel.name ? `Pay with ${data?.channel.name} via QR Ph` : "QR Ph"
  );

  if (data && theme === "1") {
    return <ContentTheme2 transactionData={data} />;
  }

  if (data && data.channel.slug !== "gcash-qrph" && !theme) {
    return <CodeContent transactionData={data} />;
  }

  if (data && data.channel.slug === "gcash-qrph" && !theme) {
    return <GcashContent transactionData={data} />;
  }

  if (error || isError) {
    return <Failed />;
  }

  return <Loading />;
};

export default ReferenceNoPage;
