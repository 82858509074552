import Img from "src/components/elements/Img";
import { Transaction } from "src/models/Transaction";
import useMountOpenModal from "src/hooks/useMountOpenModal";
import ModalHowToPay from "../../common/ModalHowToPay";
import QRContent from "./QRContent";

const GcashContent = ({
  transactionData,
}: {
  transactionData: Transaction;
}) => {
  const [modal, createModal] = useMountOpenModal();
  return (
    <div className="bg-[#E6EAED] flex-1">
      <div className="h-[270px] bg-[#0057e4] w-full fixed top-0 inset-x-0"></div>
      <div className=" flex flex-col items-center z-10 px-4 max-w-[33.75rem] w-full mx-auto">
        <Img
          src="/images/gcash.png"
          alt="GCash Logo"
          className=" max-w-[135px] z-10 lg:max-w-[240px] lg:max-h-[58px] my-6 lg:my-8 object-contain"
        />
        <QRContent transactionData={transactionData} />

        <div className="flex flex-col-reverse xl:flex-row gap-8 items-center justify-between p-4 xl:px-24 pb-8 xl:fixed inset-x-0 xl:bottom-8">
          <div className="flex items-center">
            <div className="text-[#ACACAC] font-bold uppercase mr-3 text-sm">
              Powered by
            </div>
            <Img src="/images/qrph.png" alt="QR Ph Logo" />
          </div>

          <button
            onClick={() =>
              createModal(ModalHowToPay, {
                channelName: transactionData.channel.name,
              })
            }
            className="flex flex-col items-center"
          >
            <Img src="/images/faq.png" alt="FAQ" />
            <div className="text-[#0057e4] font-bold text-sm">How to pay</div>
          </button>
        </div>
        {modal}
      </div>
    </div>
  );
};

export default GcashContent;
