import { RefObject } from "react";
import { ModalAction } from "src/models/ModalAction";
import { Img, Modal } from "src/components/elements";

interface Props {
  modalRef: RefObject<ModalAction>;
  channelName: string;
}

const ModalHowToPay = ({ modalRef, channelName }: Props) => {
  return (
    <Modal ref={modalRef}>
      <div className=" max-w-[450px] w-full mx-auto flex flex-col space-y-6 py-4">
        <Img
          src="/images/faq-l.png"
          height="97"
          width="97"
          className="self-center"
        />
        <h1 className="text-[#0057e4] text-2xl font-bold text-center">
          How to pay
        </h1>

        <ol className=" list-decimal text-lg lg:pb-8 ml-4">
          <li>Begin by launching the {channelName} mobile app.</li>
          <li>
            Access the QR Code Scanner feature and scan or download the QR Code
            and upload it. A payment summary screen will be shown once it has
            been scanned or uploaded.
          </li>
          <li>
            Adhere to any required extra steps needed to complete the payment
            process.
          </li>
          <li>
            An SMS confirmation will be sent to your mobile number once the
            payment has been made.
          </li>
          <li>
            You will be automatically redirected back to the merchant once the
            payment process is complete.
          </li>
        </ol>
      </div>
    </Modal>
  );
};

export default ModalHowToPay;
