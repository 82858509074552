import { Img } from "src/components/elements";

const Loading = () => {
  return (
    <div className=" flex flex-col items-center z-10 px-4 max-w-[33.75rem] w-full mx-auto">
      <div className=" max-w-[135px] lg:max-w-[240px] lg:max-h-[58px] w-full h-full my-6 lg:my-8 bg-gray-300 animate-pulse">
        <Img
          src="/images/gcash.png"
          alt="GCash Logo"
          className=" object-contain opacity-0"
        />
      </div>

      <div className="relative w-full bg-[#fff] rounded-md overflow-hidden text-center  pb-8 lg:pb-12 z-10">
        <div className="font-bold text-3xl pt-8 pb-4 bg-[#f7f7f9] flex flex-col items-center">
          <div className="bg-gray-300 animate-pulse mb-1">
            <div className="opacity-0">PHP 0.00</div>
          </div>
          <div className="bg-gray-300 animate-pulse">
            <div className="opacity-0 text-xl"> Amount Due...</div>
          </div>
        </div>
        <div className="flex flex-col space-y-4 lg:space-y-6 pt-4 lg:pt-6 items-center px-4 border-[#E6EAED] border-t">
          <div className="bg-gray-300 animate-pulse rounded  py-2 px-6">
            <div className="opacity-0">Pending</div>
          </div>

          <div className="flex items-center flex-col space-y-6 w-full">
            <div className="bg-gray-300 animate-pulse ">
              <p className=" text-xl opacity-0">Scan using the app to pay</p>
            </div>

            <div className=" bg-gray-300 animate-pulse max-w-[80vw]">
              <Img
                src="/images/example-qr.png"
                height={"266"}
                width={"266"}
                alt=""
                className="opacity-0 object-contain"
              />
            </div>
          </div>

          <div className=" bg-gray-300 animate-pulse">
            <div className=" opacity-0 text-base">OR</div>
          </div>

          <div className=" bg-gray-300 animate-pulse  max-w-[22.1875rem] w-full rounded-[1.6875rem]">
            <div className="opacity-0 text-white text-base font-bold py-2 lg:py-4">
              DOWNLOAD QR
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
