import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReferenceNoPage from "./pages/reference-no";
import NofFound from "./pages/not-found";
import QrphLayout from "./layout.ts/QrphLayout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<QrphLayout />}>
          <Route path=":reference_no" element={<ReferenceNoPage />} />
          <Route path="*" element={<NofFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
