const NofFound = () => {
  return (
    <div className="fixed inset-0 text-[#3D3D3D] flex flex-col justify-center">
      <div className="p-4 flex flex-col pb-24  max-w-lg w-full mx-auto">
        <div className="flex flex-col rounded-3xl pb-12">
          <div className="text-2xl">Error</div>
          <div className="text-6xl sm:text-[12rem] font-black tracking-widest text-[#6D5BD0]">
            404
          </div>
          <p>
            This page does not exist.{" "}
            <button
              onClick={() => window?.history.back()}
              className=" text-purple-500"
            >
              Go Back
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NofFound;
