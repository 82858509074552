import {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import FocusTrap from "focus-trap-react";
import { AnimatePresence, motion } from "framer-motion";
import useOnClickOutside from "src/hooks/useOnClickOutside";

interface Props {
  children?: React.ReactNode;
  maxWidth?: number;
  isSecondModal?: boolean;
  exitFunc?: () => void;
  isNoOutsideClick?: boolean;
}

// eslint-disable-next-line react/display-name
const Modal = forwardRef(
  (
    {
      children,
      maxWidth,
      isSecondModal = false,
      exitFunc,
      isNoOutsideClick,
    }: Props,
    ref
  ) => {
    const modalContentRef = useRef<HTMLDivElement>(null);
    const [display, setdisplay] = useState(false);

    const variants = useMemo(
      () => ({
        initial: {
          opacity: 0.8,
          y: 10,
        },
        entrance: {
          opacity: 1,
          y: 0,
        },
        exit: {
          opacity: 0.8,
          y: 10,
        },
      }),
      []
    );

    const open = () => setdisplay(true);

    const close = () => {
      setdisplay(false);
      if (exitFunc) {
        return exitFunc();
      }
    };

    useEffect(() => {
      if (display) {
        document.body.style.overflowY = "hidden";
      }
      return () => {
        if (display && !isSecondModal) {
          document.body.style.overflowY = "auto";
        }
      };
    }, [display, isSecondModal]);

    useOnClickOutside(modalContentRef, () => {
      if (!isNoOutsideClick && display) {
        close();
      }
    });

    useImperativeHandle(ref, () => {
      return {
        openModal: () => open(),
        closeModal: () => close(),
        isOpen: () => display,
      };
    });

    return ReactDOM.createPortal(
      <AnimatePresence>
        {display && (
          <FocusTrap
            focusTrapOptions={{
              initialFocus: false,
              fallbackFocus: "body",
            }}
          >
            <motion.div
              style={{ backdropFilter: "blur(3px)" }}
              className={[
                "fixed overflow-y-auto overflow-x-hidden  inset-0 transform-gpu flex justify-center items-center flex-wrap bg-[#000000] bg-opacity-50 z-50 bg-clip-padding transition-opacity",
                display ? "opacity-100" : "opacity-0 pointer-events-none",
              ].join(" ")}
            >
              <motion.div
                variants={variants}
                initial="initial"
                animate="entrance"
                transition={{
                  ease: "easeIn",
                }}
                style={{ maxWidth }}
                ref={modalContentRef}
                className={[
                  "relative mx-auto my-8 px-2 z-[60] max-w-[640px] w-full  origin-center",
                  display ? "scale-100 opacity-100" : "scale-70 opacity-0",
                ].join(" ")}
              >
                <div className=" bg-white rounded-lg py-8 px-4">
                  <button
                    type="button"
                    onClick={() => close()}
                    className="absolute  top-4 right-6 z-[60] select-none"
                  >
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.38467 8.58579C9.10063 7.86983 9.98253 7.56833 10.4527 7.8512L10.5703 7.94296L25.9981 23.3707C26.4241 23.7968 26.1363 24.7753 25.3552 25.5563C24.6393 26.2723 23.7574 26.5738 23.2872 26.2909L23.1696 26.1992L7.74185 10.7714C7.31582 10.3454 7.60362 9.36684 8.38467 8.58579Z"
                        fill="#3D3D3D"
                      />
                      <path
                        d="M25.3847 8.41421C26.1006 9.13017 26.4021 10.0121 26.1193 10.4822L26.0275 10.5998L10.5997 26.0276C10.1737 26.4536 9.19516 26.1658 8.41411 25.3848C7.69815 24.6688 7.39665 23.7869 7.67952 23.3168L7.77128 23.1992L23.1991 7.77139C23.6251 7.34536 24.6036 7.63316 25.3847 8.41421Z"
                        fill="#3D3D3D"
                      />
                    </svg>
                  </button>
                  {children}
                </div>
              </motion.div>
            </motion.div>
          </FocusTrap>
        )}
      </AnimatePresence>,
      document.body
    );
  }
);

export default Modal;
