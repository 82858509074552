import { Img } from "src/components/elements";

const Failed = () => {
  return (
    <div className="bg-[#F5F5F5] text-[#3D3D3D] flex flex-col min-h-screen justify-center px-4">
      <div className="max-w-[472px] mx-auto w-full flex flex-col items-center bg-white shadow-card rounded-2xl p-10 font-bold">
        <Img src="/images/status-times.png" className=" object-contain mb-4" />
        <div>Transaction does not exist.</div>
      </div>
    </div>
  );
};

export default Failed;
